<template>
  <!-- <dv-loading v-if="!totalData">加载中...</dv-loading> -->
  <div id="index" ref="appRef">
    <div class="bg">
      <!-- <dv-loading v-if="!totalData">加载中...</dv-loading> -->
      <div class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span class="title-text">浔食安智慧监管平台</span>
              <dv-decoration-1 class="dv-dec-6" :reverse="true" :color="['#50e3c2', '#67a1e5']" />
            </div>
            <dv-decoration-8 class="dv-dec-8" :reverse="true" :color="decorationColor" />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex aside-width">
            <div class="react-left ml-7 react-l-s">
              <span class="react-left" style="top:-48px;cursor:pointer;" @click="go">综合查一次</span>
              <span class="text">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
            </div>

          </div>
          <div class="d-flex aside-width">
            <div class="react-right bg-color-blue mr-7">
              <span class="text fw-b">最近一次数据更新时间：{{ dateYear1 }} {{ dateWeek1 }} {{ dateDay1 }}</span>
            </div>
          </div>
        </div>

        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="content-box">
            <div class="top" v-if="totalData">
              <dv-border-box-8>
                <div class="item-1">
                  <div class="item-1-left"></div>
                  <div class="item-1-center">
                    <p>
                      <b>{{ totalData.allNumber }}</b>
                      个
                    </p>
                    <p>
                      主题总数
                    </p>
                  </div>
                  <div class="item-1-right">
                    <div class="item-1-right-top">
                      <span>本月新增</span>
                      <div class="items">
                        <p
                          :style="'width:' + (totalData.monthNewNumber === 0 ? 0 : ((totalData.monthNewNumber / totalData.allNumber))) * 100 + '%'">
                        </p>
                      </div>
                      <p style="margin-left:10px;">{{ totalData.monthNewNumber }}</p>
                    </div>
                    <div class="item-1-right-bottom">
                      <span>本月停业</span>
                      <div class="items">
                        <p
                          :style="'width:' + (totalData.monthStopNumber === 0 ? 0 : (totalData.monthStopNumber / totalData.stopNumber)) * 100 + '%'">
                        </p>
                      </div>
                      <p style="margin-left:10px;">{{ totalData.monthStopNumber }}</p>
                    </div>
                  </div>
                </div>

                <div class="item-2">
                  <div class="item-2-items">
                    <div>
                      <img src="@/assets/point.png" />
                      <span>社会餐饮</span>
                    </div>
                    <b>{{ totalData.threeSmallTotal }}</b>
                  </div>
                  <div class="item-2-items">
                    <div>
                      <img src="@/assets/point.png" />
                      <span>集体食堂</span>
                    </div>
                    <b>{{ totalData.collectiveCanteensTotal }}</b>
                  </div>
                  <div class="item-2-items">
                    <div>
                      <img src="@/assets/point.png" />
                      <span>网络餐饮</span>
                    </div>
                    <b>{{ totalData.takeOutCateringTotal }}</b>
                  </div>
                </div>
                <div class="top2-tit">
                  <dv-decoration-2 style="width:200px;height:5px;" />
                  <p class="info">数量占比</p>
                </div>
                <div class="top-3">
                  <dv-active-ring-chart v-if="totalData && config4Value !== 0" :config="config4"
                    style="width: 70%;height: 312px;margin-top: 15px;" />
                  <dv-active-ring-chart v-else :config="config4Empty"
                    style="width: 70%;height: 312px;margin-top: 15px;" />
                  <div class="points">
                    <div class="points1" v-for="(item, index) in config4.data" :key="index">
                      <div class="radius" :style="'background:' + color[index]"></div>
                      <span>{{ item.name }}</span>
                    </div>

                  </div>
                </div>
              </dv-border-box-8>
            </div>
            <div class="bottom">
              <dv-border-box-8>
                <dv-decoration-2 style="width:200px;height:5px;" />
                <p class="info">阳光厨房:{{ videoName }} <span @click="lookMore">查看更多>></span></p>

                <div class="kich" v-if="kich.length === 0">阳光厨房为空哦</div>
                <!-- <div class="kichList" v-else></div> -->
                <!--  id="example-video" -->
                <div class="glplayer" v-if="kich.length !== 0">
                  <!-- :video-title="videoName"  -->
                  <div style="width:100%;height: 100%;" v-if="kich[videoIndex].status === 1">
                    <EasyPlayer alt="加载中..." :pause="pause" :stretch="true" :videoUrl="videoUrl" aspect="16:9" live
                      @message="$message" :fluent="true" :autoplay="true">
                    </EasyPlayer>
                  </div>
                  <div
                    style="background: #000; font-size: 22px;width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;"
                    v-else>离线中</div>
                </div>
              </dv-border-box-8>


            </div>
          </div>
          <div class="content-box">
            <div>
              <dv-border-box-8>
                <el-select style="margin-top:10px;margin-left:10px" placeholder="请选择" v-model='regionValue'
                  @change="regionChange">
                  <el-option v-for="item in streetAry" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>

                <div class="map" id="map" v-if="regionValue && regionValue !== '全部'">
                </div>
                <centerLeft2 @getRegion="regionChange" v-show="!regionValue || regionValue === '全部'" />
              </dv-border-box-8>
            </div>

            <div>
              <dv-border-box-8>
                <div class="titList">
                  <p v-for="(item, index) in titList" :key="index" :class="hoverIndex === index ? 'hover' : ''"
                    @click="titFun(index)">
                    <dv-border-box-1>{{ item }}</dv-border-box-1>
                  </p>
                </div>
              </dv-border-box-8>
            </div>
          </div>

          <!-- 右 -->
          <div class="content-box">
            <div class="top-4">
              <dv-border-box-8>
                <div class="center-bottom">
                  <div class="top2-tit">
                    <span class="info">餐饮服务食品安全等级公示</span>
                    <dv-decoration-1 style="width:40%" class="dv-dec-1" />
                  </div>
                  <div class='add-lever'>
                    <div class="lever-item">
                      <img src="../assets/1_slices/A.png" />
                      <div style="width: 110px;">
                        <span>A级</span>
                        <div v-if="totalData">{{ totalData.levelATotal }}家</div>
                      </div>
                    </div>
                    <div class="lever-item">
                      <img src="../assets/1_slices/B.png" />
                      <div style="width: 110px;">
                        <span>B级</span>
                        <div v-if="totalData">{{ totalData.levelBTotal }}家</div>
                      </div>
                    </div>
                    <div class="lever-item">
                      <img src="../assets/1_slices/C.png" />
                      <div style="width: 110px;">
                        <span>C级</span>
                        <div v-if="totalData">{{ totalData.levelCTotal }}家</div>
                      </div>
                    </div>
                    <div class="lever-item">
                      <img src="../assets/1_slices/D.png" />
                      <div style="width: 110px;">
                        <span>未评定</span>
                        <div v-if="totalData">{{ totalData.levelNoTotal }}家</div>
                      </div>
                    </div>
                  </div>


                </div>
              </dv-border-box-8>
            </div>
            <div class="top-4">
              <dv-border-box-8>
                <div class="center-bottom">
                  <div class="top2-tit">
                    <span class="info">外卖商户</span>
                    <dv-decoration-1 style="width:60%" class="dv-dec-1" />
                  </div>

                  <div class="top-1 top-1-manage">

                    <div class="left">
                      <p>商户总数：<b v-if="totalData">{{ totalData.takeOutShopTotal }}</b></p>

                    </div>
                    <div class="left">
                      <p>美团：<b v-if="totalData">{{ totalData.meiTuanTotal }}</b></p>



                    </div>
                    <div class="left">
                      <p>饿了么：<b v-if="totalData">{{ totalData.eleMeTotal }}</b></p>



                    </div>
                    <div class="left">
                      <p>多平台：<b v-if="totalData">{{ totalData.multiPlatformTotal }}</b></p>



                    </div>

                  </div>
                  <div class="manage">
                    <p style="width:50%;">运营商占比</p>
                    <p style="width:50%;">网络餐饮阳光厨房安装比例</p>

                  </div>
                  <div class="top-2-manage" v-if="totalData">
                    <div class="top-3" style="width:60%;display: flex;">
                      <dv-active-ring-chart v-if="totalData && config2Value !== 0" :config="config2"
                        style="width: 70%;height: 312px;" />
                      <dv-active-ring-chart v-else :config="config4Empty" style="width: 70%;height: 312px;" />
                      <div class="points">
                        <div class="points1" v-for="(item, index) in config2.data" :key="index">
                          <div class="radius" :style="'background:' + color[index]"></div>
                          <span>{{ item.name }}</span>
                        </div>
                      </div>
                    </div>
                    <dv-water-level-pond v-if="totalData" :config="configWater"
                      style="width:30%;height:190px;margin-left:2%" />

                  </div>

                </div>
              </dv-border-box-8>
            </div>
            <div class="top-5">
              <dv-border-box-8>
                <div class="center-bottom center-bottom1">
                  <div class="top2-tit">
                    <span class="info" style='width:100%;text-align:center'>食品安全承诺书签订</span>
                    <p v-if="totalData">{{ totalData.undertakingTotal }}<span>家</span></p>
                  </div>
                </div>
              </dv-border-box-8>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div clas="modals" v-if="modal">
      <videoList @closeModal="closeModal" :region='regionValue' :checkDetails="checkDetails"
        :fellowPersonnelList="fellowPersonnelList" />

    </div>
    <div clas="modals" v-if="modal1">
      <modal @closeModal="closeModal" :checkDetails="checkDetails" :fellowPersonnelList="fellowPersonnelList" />
    </div>
  </div>
</template>
<script>
  import drawMixin from "../utils/drawMixin";
  import { formatTime } from '../utils/index.js'
  import centerLeft2 from './centerLeft2'
  import videoList from './videoList'
  import modal from './modal'
  import * as echarts from 'echarts';
  import AMapLoader from '@amap/amap-jsapi-loader';
  // import { Player } from "../utils/executor";
  import EasyPlayer from '@easydarwin/easyplayer'
  import { shopList, shopCheck, statistics, macList, macIdVideo, keepLive, shopChecks } from "@/api/login";
  export default {
    mixins: [drawMixin],
    components: {
      centerLeft2,
      videoList,
      EasyPlayer,
      modal
    },
    data() {
      return {
        modal1: false,
        videoloading: false,
        flvPlayer: null,
        kich: [],
        configWater: {
          shape: 'round'
        },
        hoverIndex: 0,
        titList: ['决策统计', '监督检查', '农村家宴'],
        fellowPersonnelList: [],
        regionValue: '',
        timePicker: [],
        totalData: null,
        checkDetails: {},
        streetAry: ['全部', '东迁街道', '南浔镇', '练市镇', '双林镇', '菱湖镇', '和孚镇', '善琏镇', '石淙镇', '千金镇', '旧馆街道'],
        details: {},
        doorsList: [],
        map: null,
        modal: false,
        config8: {},
        config9: {},
        color: ['#8ABDF3', '#4367DC', '#54DED7', '#EEE397', '#FAAD14', '#dddddd'],
        config13: {
          number: [],
          content: '{nt}',

        },
        config3: {
          number: [],
          content: '{nt}',
          style: {
            fontSize: 35,
            fill: '#3de7c9'
          }
        },
        config1: {
          number: [],
          content: '{nt}'
        },
        config10: {
          number: [],
          content: '{nt}'
        },
        config11: {
          number: [],
          content: '{nt}'
        },
        config12: {
          number: [],
          content: '{nt}'
        },
        config7: {
          digitalFlopStyle: {
            fontSize: 15,
            fill: '#fff'
          },
          lineWidth: 15
        },
        config6: {
          digitalFlopStyle: {
            fontSize: 15,
            fill: '#fff'
          },
          lineWidth: 15
        },
        config5: {
          digitalFlopStyle: {
            fontSize: 22,
            fill: '#fff'
          },
          lineWidth: 15,
          showOriginValue: true
        },
        config4: {
          digitalFlopStyle: {
            fontSize: 14,
            fill: '#fff'
          },
          lineWidth: 15,
          showOriginValue: true,
          color: this.color
        },
        config4Empty: {
          digitalFlopStyle: {
            fontSize: 0,
            fill: '#fff'
          },
          lineWidth: 15,
          showOriginValue: true,
          data: [{
            name: '',
            value: 1
          }],
          color: ['#dddddd']
        },
        config2: {
          digitalFlopStyle: {
            fontSize: 14,
            fill: '#fff'
          },
          lineWidth: 15,
          showOriginValue: true
        },
        timing: null,
        loading: true,
        dateDay: null,
        dateYear: null,
        dateWeek: null,
        dateDay1: null,
        dateYear1: null,
        dateWeek1: null,
        weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
        decorationColor: ['#568aea', '#000000'],
        config: {
          data: [
            {
              name: '合格数'
            },
            {
              name: '隐患数'
            },
            {
              name: '整改数'
            }
          ],
          colors: ['#1B7EF2', '#BD9000', '#58A600'],
          showValue: true
        },
        videoUrl: null,
        videoName: null,
        videoIndex: 0,
        player: null,
        options: {
          height: 357,
          autoplay: true, // 设置自动播放
          muted: true, // 设置了它为true，才可实现自动播放,同时视频也被静音（Chrome66及以上版本，禁止音视频的自动播放）
          preload: 'auto', // 预加载
          controls: true // 显示播放的控件
        },
        config4Value: 0,
        config2Value: 0,
        keepLiveTimer: null,
        mapCenter: null,
        times: null,
        instance: null,
        pause: false
      }
    },
    mounted() {
      this.timeFn()
      this.cancelLoading()
      this.initAMap()
      // this.shopList('双林镇')
      this.audit(this.regionValue, '', '')
      this.times = setInterval(() => {
        this.audit(this.regionValue, '', '')

      }, 60 * 5 * 1000)

    },
    beforeDestroy() {
      this.pause = true
      clearInterval(this.timing)
      clearInterval(this.keepLiveTimer)
      clearInterval(this.times)
      this.destoryVideo()
    },

    methods: {
      returnCenter(name) {
        var center
        if (name === '南浔镇') center = { lng: 120.431469, lat: 30.872505 }
        else if (name === '练市镇') center = { lng: 120.429009, lat: 30.712698 }
        else if (name === '东迁街道') center = { lng: 120.426852, lat: 30.872926 }
        else if (name === '双林镇') center = { lng: 120.336816, lat: 30.778398 }
        else if (name === '和孚镇') center = { lng: 120.16947, lat: 30.783195 }
        else if (name === '旧馆街道') center = { lng: 120.28861, lat: 30.841296 }
        else if (name === '石淙镇') center = { lng: 120.273657, lat: 30.726523 }
        else if (name === '善琏镇') center = { lng: 120.324178, lat: 30.705508 }
        else if (name === '千金镇') center = { lng: 120.248064, lat: 30.682385 }
        else if (name === '菱湖镇') center = { lng: 120.190923, lat: 30.718937 }
        return center
      },
      // 关闭弹窗
      closeModal() {
        // for (let i = 0; i < player.length; i++) {
        //     player[i].dispose()
        // }
        this.modal1 = false
        this.modal = false
      },
      destoryVideo() {
        // if (this.player) {
        //     this.player.dispose();
        //     this.totalData = {}
        //     this.doorsList = []
        //     this.videoUrl = null
        //     this.videoIndex = 0
        //     this.player = null
        // }
      },
      // 视频保活接口
      playFun() {
        const macId = this.kich.map(item => {
          return item.macId
        })
        this.keepLiveTimer = setInterval(() => {
          keepLive({ macId: macId }).then(() => { })
        }, 20000)
        this.$once("hook:beforeDestroy", () => {
          clearInterval(this.keepLiveTimer);
        });
      },
      pauseFun() {
        clearInterval(this.keepLiveTimer)
      },

      lookMore() {
        // this.instance.play();
        this.modal = true
      },
      go() {
        this.$router.push({
          path: '/indexCheck'
        })
      },
      titFun(index) {
        if (index === 2) {
          this.$router.push({
            path: '/indexFamilial'
          })
        }
        else if (index === 1) {
          this.$router.push({
            path: '/indexInspect'
          })
        }
      },
      regionChange(vals) {
        clearInterval(this.keepLiveTimer)
        this.regionValue = vals.name ? vals.name : vals
        this.mapCenter = vals.center ? vals.center : this.returnCenter(vals)
        this.totalData = null
        this.doorsList = []
        this.videoUrl = null
        this.videoName = ''
        this.videoIndex = 0
        this.player = null

        this.shopList(this.regionValue === '全部' ? '' : this.regionValue)
        this.audit(this.regionValue === '全部' ? '' : this.regionValue)
      },
      shopList(val) {
        shopList(val).then(res => {
          this.doorsList = res.data
          this.initAMap()
        })
      },
      shopCheck(shopId, cb) {
        shopCheck(shopId).then(res => {
          this.checkDetails = res.data
          this.fellowPersonnelList = res.data.fellowPersonnelList.map(item => {
            return item.nickName
          })
          cb()
        })
      },
      isFalse(data, cb) {
        var value = 0
        for (let i = 0; i < data.length; i++) {
          value += data[i].value
        }
        if (value === 0) {
          data = data.map(item => {
            item.value = 1
            return item
          })
          cb(data)
        }

      },
      getVodeo(list) {
        this.videoloading = true
        if (!list) return
        macIdVideo(list.macId).then(res => {
          this.videoloading = false
          this.videoName = list.name
          this.videoUrl = res.data
          // this.$nextTick(() => {
          //     this.getVideo(this.videoUrl)
          // })
        })
      },
      getMacIdVideo() {
        const times = setInterval(() => {
          this.videoIndex++
          if (this.videoIndex === this.kich.length) {
            this.videoIndex = 0
          }
          if (this.kich[this.videoIndex].status === 1) {
            this.getVodeo(this.kich[this.videoIndex])
          }
        }, 300000)
        this.$once("hook:beforeDestroy", () => {
          clearInterval(times);
        });
      },
      // 获取阳光厨房
      macList(val) {
        macList(val, '', '', 1).then(res => {
          this.kich = res.rows
          if (!res.rows.length) return
          this.playFun(this.videoIndex)
          if (res.rows[0].status === 1) {
            this.getVodeo(res.rows[0])
          }
          this.getMacIdVideo()
        })
      },

      // 获取综合检查
      audit(val) {
        this.dateDay1 = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear1 = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek1 = this.weekday[new Date().getDay()]
        statistics(val, '', '').then(res => {
          this.macList(val)
          this.totalData = res.data
          this.config4Value = 0
          this.config2Value = 0
          this.config4.data = res.data.networkCateringTotalList.map((item) => {
            this.config4Value = this.config4Value + Number(item.value)
            return {
              name: item.type,
              value: item.value
            }
          })
          this.config2.data = res.data.operatorTotalList.map((item) => {
            this.config2Value = this.config2Value + Number(item.value)
            return {
              name: item.type,
              value: item.value
            }
          })
          this.configWater.data = null
          this.configWater.data = [Number(res.data.sunshineKitchenRatio)]
        })
      },
      initAMap() {
        var markerList = []
        AMapLoader.load({
          key: 'ac02bea89e01b580212ded3b62b43682',  //设置您的key
          version: "2.0",
          plugins: ['AMap.ToolBar', 'AMap.Driving'],
          AMapUI: {
            version: "1.1",
            plugins: [],

          },
          Loca: {
            version: "2.0"
          },
        }).then((AMap) => {
          var infoWindow = new AMap.InfoWindow({
            isCustom: true,  //使用自定义窗体
            content: '',
            offset: new AMap.Pixel(120, -30)
          });
          for (let i = 0; i < this.doorsList.length; i++) {
            const item = this.doorsList[i]
            const marker = new AMap.Marker({
              position: new AMap.LngLat(Number(item.longitude), Number(item.latitude))
            });
            marker.content = []
            marker.content.push("<div style='margin-bottom:-10px;opacity:1;width: 229px;height: 112px;padding:10px;background: #092042 linear-gradient(225deg, #092348 0%, #0B3E79 100%); '><div style='display: flex;align-items:center;'><img onclick='imgFun(" + i + ")' src='" + item.shopImg + "' style='cursor: pointer;width:50px;height:50px;margin-right:20px;'/><b style='color:#00BAFF;font-size:16px;margin-top:-20px;'>" + item.name + "</b></div><p onclick='handleBreak(" + i + ")' style='cursor:pointer;margin-top:20px;font-size:12px;border-radius:5px;text-align:center;line-height:26px;width: 68px;height: 26px;color: #0B3C76;background: #D8D8D8 linear-gradient(270deg, #00BAFF 0%, #00FFFC 100%); '>查看检查</div>");
            // marker.content.push("<div style='width:14px;height:24px;border-right:2px solid #0883FD;transform:rotate(-158deg)'></div>")
            marker.on('click', (e) => {
              infoWindow.open(window.map, marker.getPosition());
              infoWindow.setContent(e.target.content);
            });
            marker.emit('click', { target: marker });
            //实例化信息窗体
            window.handleBreak = (i) => {
              shopChecks(this.doorsList[i].id).then(res => {
                this.checkDetails = res.data
                this.fellowPersonnelList = res.data.fellowPersonnelList.map(item => {
                  return item.nickName
                })
                this.modal1 = true
                this.$forceUpdate()
              })

            }
            window.imgFun = () => {
              this.shopImg = item.shopImg
            }
            markerList.push(marker)
          }
          window.map = new AMap.Map("map", {
            mapStyle: 'amap://styles/darkblue', //设置地图的显示样式
            viewMode: "3D",
            zoom: 17,
            center: [Number(this.mapCenter.lng), Number(this.mapCenter.lat)]
          });
          window.map.add(markerList)
          window.map.setFitView();
        }).catch(() => {
        })
      },

      chart1() {
        let option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '5%',
            left: 'center'
          },
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 40,
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: [
                { value: 1048, name: 'Search Engine' },
                { value: 735, name: 'Direct' },
                { value: 580, name: 'Email' },
                { value: 484, name: 'Union Ads' },
                { value: 300, name: 'Video Ads' }
              ]
            }
          ]
        };
        // 初始化echart
        this.chart = echarts.init(document.getElementById('chart1'))
        this.chart.setOption(option, true)
      },
      timeFn() {
        this.timing = setInterval(() => {
          this.dateDay = formatTime(new Date(), 'HH: mm: ss')
          this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
          this.dateWeek = this.weekday[new Date().getDay()]
        }, 1000)
        // this.chart1()
      },
      cancelLoading() {
        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../assets/scss/index.scss';

  .video-js {
    height: 357px;
    background: none;
    width: 100%;
  }

  // ::v-deep .video-js .vjs-tech {
  //     width: 90%;
  //     height: 357px;
  //     margin-left: 5%;
  //     margin-top: -10px;
  // }

  .item-1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 140px;

    .item-1-left {
      width: 69px;
      height: 69px;
      background: url("../assets/icon.png") no-repeat center;
      background-size: 100% 100%;
      margin-left: 20px;
    }

    .item-1-center {
      color: rgba(255, 255, 255, 0.8);
      font-size: 18px;

      p {
        margin-bottom: 10px;
        margin-top: 5px;
      }

      b {
        font-weight: bold;
        color: #fff;
        font-size: 30px;

      }
    }

    .item-1-right {
      font-size: 18px;
      color: rgba(208, 222, 238, 1);
      margin-right: 30px;
      margin-bottom: 10px;

      .items {
        background: rgba(108, 128, 151, 0.1);
        width: 110px;
        height: 18px;

        p {
          height: 18px;
          width: 100%;
          margin-top: 3px;
        }
      }

      .item-1-right-top {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        div {
          margin-left: 10px;

          p {
            background: linear-gradient(270deg, #5C8DC6 0%, rgba(0, 89, 84, 0) 100%);
          }
        }
      }

      .item-1-right-bottom {
        display: flex;
        align-items: center;

        div {
          margin-left: 10px;

          p {
            background: linear-gradient(270deg, #FFB742 0%, rgba(255, 183, 66, 0) 100%);
          }
        }
      }
    }

  }

  .item-2 {
    width: 90%;
    background: rgba(108, 128, 151, 0.1);
    border-top: 1px solid #3A70AE;
    height: 80px;
    display: flex;
    align-items: center;
    margin: 0 5% 20px 5%;

    // margin-top: calc(100vw * 43 / 1920);
    .item-2-items {

      width: 33%;
      text-align: center;

      div {
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, 0.8);
        width: 100%;
        justify-content: center;
        font-size: 18px;
        margin-bottom: 5px;

        img {
          width: 26px;
          height: 26px;
        }
      }

      b {
        font-weight: bold;
        font-size: 22px;
        color: #fff;
      }
    }
  }

  .kich {
    width: 100%;
    height: 250px;
    line-height: 250px;
    text-align: center;

  }

  #example-video {
    // background: #ddd !important;
  }

  .glplayer {
    margin-top: 10px;
    margin-left: 10px;
    height: 320px;
    width: 450px;
    background: transparent;
  }

  .titList {
    display: flex;
    justify-content: center;
    font-size: 25px;
    height: 220px;

    p {
      cursor: pointer;
      width: 200px;
      margin: 50px 0;
      margin-left: 3%;
      height: 100px;
      line-height: 100px;
      text-align: center;
      border-radius: 10px;
      // border: 2px solid #fff;
    }

    .hover {
      background: #1760AE;
      // border: 3px solid #1760AE;
    }
  }

  .opacity {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.8;
    z-index: 1;
  }

  .close {
    position: fixed;
    right: 50%;
    width: calc(100vw * 50 / 1920);
    height: auto;
    z-index: 557777666667;
    cursor: pointer;
    top: 28%;
    margin-right: -250px;
  }

  .shopImg {
    position: fixed;
    top: 30%;
    left: 50%;
    width: 400px;
    margin-left: -200px;
    z-index: 2;
  }

  .example-video {
    margin-top: 20px;
  }

  #map {
    height: 680px;
  }

  ::v-deep .el-select .el-input__inner:focus {
    background: none;
    color: #fff;
    margin-right: 10px;
  }

  ::v-deep .el-input--medium .el-input__inner,
  ::v-deep .el-range-editor--medium.el-input__inner,
  ::v-deep .el-range-editor--medium .el-range-input,
  ::v-deep .el-range-editor--medium .el-range-separator {
    background: 0;
    color: #fff;
  }

  .top,
  .bottom {
    height: 470px;

    .titles {
      font-size: 22px;
      display: flex;
      align-items: center;
      font-weight: bold;

      img {
        width: 80px;
        margin: 5px 10px;
      }
    }

    .top-1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 10px;

      .left {
        font-size: 22px;
        width: 30%;
        background: url(../assets/grey.png);
        background-size: 100% 100%;
        height: 110px;
        margin-top: 20px;

        p {
          margin: 20px;
        }
      }
    }

    .top-2 {
      display: flex;
      align-items: center;
      justify-content: center;

      .chart1 {
        width: 50%;
        height: 130px;
      }
    }

    .info {
      padding-top: 10px;
      margin-left: 10px;
      font-size: 22px;
      font-weight: bold;
      width: 100%;

      span {
        float: right;
        font-size: 13px;
        margin-right: 30px;
        cursor: pointer;

      }
    }

    .points1 {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #fff;
      margin-bottom: 8px;

      .radius {
        width: 8px;
        height: 8px;
        background: red;
        border-radius: 50%;
        margin-right: 5px;
      }
    }

    .top-3 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -40px;


    }

  }

  .top {
    height: 550px;
  }

  .bottom {
    height: 386px;
  }

  .bottom img {
    width: 80px;
    margin: 5px 10px;
  }

  .top-4 {
    height: 400px;
  }

  .top-5 {
    height: 133px;
  }

  .center-bottom {
    .add-lever {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 30px;

      .lever-item {
        width: 50%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-top: 20px;

        img {
          width: 120px;
          height: 120px;
          margin-right: 10px;
        }

        >div {
          span {
            font-size: 25px;
          }

          div {
            font-size: 28px;
            color: #3de7c9;
            margin-top: 10px;
            font-weight: bold;
          }
        }
      }
    }

    height: 222px;

    .top-3 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -80px;
    }

    .top2-tit {
      font-size: 22px;
      display: flex;
      font-weight: bold;
      align-items: center;

      img {
        width: 80px;
        margin: 5px 10px;
      }
    }

    .info {
      padding-top: 10px;
      margin-left: 10px;
      font-size: 22px;
      font-weight: bold;
    }

    .points1 {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #fff;
      margin-bottom: 8px;

      .radius {
        width: 8px;
        height: 8px;
        background: red;
        border-radius: 50%;
        margin-right: 5px;
      }
    }

    .top-1 {
      margin: 20px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        margin-right: 10px;
        font-weight: bold;
      }
    }

    .top-1-manage {
      font-size: 20px;
      flex-wrap: wrap;

      .left {
        font-size: 20px;
        width: 45%;
        text-align: center;
        margin-bottom: 20px;

        b {
          font-size: 28px;
          color: #3de7c9;
        }
      }
    }

    .manage {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      margin-top: 10px;
      color: #fff;
      text-align: center;
    }

    .top-2-manage {
      margin-left: -40px;
      display: flex;
      align-items: center;

      margin-top: -15px;

      .top-3 {
        display: block;
        width: 50%;
      }
    }

    .bottom-tit {
      display: flex;
      justify-content: space-between;
    }

    .top3-tit2 {
      margin-right: 20px;
    }

    .width {
      width: 50%;
      text-align: center;
    }

    .top-3 {
      margin-top: -40px;
    }
  }

  .center-bottom1 {
    height: 100%;

    .top2-tit {
      display: block;
      text-align: center;
      padding-top: 35px;

      p {
        font-size: 30px;
        margin-top: 20px;
        color: #3de7c9;

        span {
          font-size: 15px;
        }
      }
    }
  }

  .dv-dec-1 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }

  .points2 {
    margin-top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .points1 {
      margin-right: 5px;
      width: 40%;
    }
  }

  .hot {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1%;

    .hot-1 {
      width: 49%;
      height: 100px;

      .hot-1-1 {
        padding-top: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .hot-1-3 {
          position: absolute;
          width: 100%;
          text-align: center;
          bottom: 20px;
          font-size: 19px;
        }

        .hot-1-2 {
          font-size: 19px;
        }
      }
    }
  }

  .pond {
    width: 100%;
    height: 90px;
    margin-top: 10px;
    display: flex;
    align-items: center;

    // justify-content: center;
    span {
      font-size: 18px;
      margin-right: 20px;
      margin-left: 10px;
    }
  }

  .pond2 {
    height: 160px;
  }

  .list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.5%;
    margin-top: 0px;

    .list-1 {
      width: 49%;
      height: 180px;

      div {
        padding: 5px;

        p {
          text-align: left;
          font-size: 18px;
          color: #fff;
          line-height: 1.3;
        }
      }

    }

    .list-2 {
      width: 60%
    }

    .list-3 {
      width: 39%;
    }

    .list-4 {
      width: 60%;
      height: 260px;
      margin-top: -30px;

      div {
        padding-left: 50px;
        padding-top: 20px;
        line-height: 2;
      }
    }
  }

  .list2 {
    height: 210px;
    justify-content: center;

    .list-1 {
      width: 60%;
    }
  }
</style>