<template>
  <div class="modal">
    <img src="../assets/close.png" class="close" @click="closeModal">
    <div class="opacity"></div>
    <div class="details">
      <div class="title">阳光厨房</div>
      <div v-if="loading" class="items">
        <div class="item" v-for="(item, index) in  kich " :key="index">
          <!-- <div v-if="item.status === 1" style="width:100%;height: 100%;">
            <EasyPlayer v-if="item.src" alt="加载中..." :pause="pause" :video-title="item.name" :videoUrl="item.src"
              aspect="16:16" live @message="$message" :fluent="true" :autoplay="true" stretch></EasyPlayer>
          </div> -->

          <div
            style="background: #000; font-size: 22px;width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
            加载中...
          </div>
        </div>
      </div>
      <div v-else class="items list">
        <div class="item" v-for="(item, index) in  player " :key="index">
          {{item.status}}
          <div v-if="item.status === 1">
            <EasyPlayer style="height:300px" :pause="pause" :videoUrl="item.src" aspect="16:9" live @message="$message"
              :autoplay="true" :changeStretch="false" :snapshot="false" :switchRecording="false">
            </EasyPlayer>
            <p class="name">{{ item.name }}</p>
          </div>
          <div v-else style="width: 100%;height: 100%;">
            <div
              style="background: #000; font-size: 22px;width: 100%;height: 300px;display: flex;align-items: center;justify-content: center;">
              离线中
            </div>
            <p class="name">{{ item.name }}</p>
          </div>
        </div>

        <el-pagination small :current-page.sync="pageNum" @current-change="currentChange" :page-size="4"
          layout="total, prev, pager, next" :total="total">
        </el-pagination>
      </div>
    </div>

  </div>
</template>
<script>
  import { macList, macIdVideo, keepLive } from "@/api/login";
  import EasyPlayer from '@easydarwin/easyplayer'
  export default {
    props: ['region'],
    components: {
      EasyPlayer
    },
    data() {
      return {
        total: 0,
        pageNum: 1,
        loading: true,
        player: [],
        options: {
          autoplay: true, // 设置自动播放
          muted: true, // 设置了它为true，才可实现自动播放,同时视频也被静音（Chrome66及以上版本，禁止音视频的自动播放）
          preload: 'auto', // 预加载
          controls: true // 显示播放的控件
        },
        kich: [],
        macIds: [],
        keepLiveTimer: null,
        pause: false
      }
    },
    methods: {
      currentChange(current) {
        this.pause = true
        this.loading = true
        clearInterval(this.keepLiveTimer)
        this.macList(this.region, current ? current : 1)
      },
      // 视频保活接口
      playFun() {
        // if (this.macIds.length < this.kich.length) {
        //     this.macIds.push(this.kich[index].macId)
        // }

        const macId = this.kich.map((item) => {
          return item.macId
        })
        this.keepLiveTimer = setInterval(() => {
          keepLive({ macId: macId }).then(() => { })
        }, 20000)
      },
      // 关闭弹窗
      closeModal() {
        this.pause = true
        this.loading = true
        clearInterval(this.keepLiveTimer)
        this.$emit('closeModal', this.player)
      },
      // 获取阳光厨房
      macList(val, pageNum) {
        this.loading = true
        macList(val === '全部' ? '' : val, '', '', pageNum).then(res => {
          this.kich = res.rows
          this.total = res.total
          this.playFun()
          this.player = Array.from({ length: this.kich.length }, () => { return [] })
          for (let i = 0; i < res.rows.length; i++) {
            this.player[i] =
            {
              name: this.kich[i].name,
              status: this.kich[i].status
            }
            if (res.rows[i].status === 1) {
              this.getVodeo(res.rows[i], i)
            }
          }
        })
      },
      getVodeo(list, i) {
        macIdVideo(list.macId).then(res => {
          this.videoUrl = res.data
          this.$nextTick(() => {
            this.videoShow(res.data, i)
          })

        })
      },
      videoShow(videoUrl, index) {
        // player.play();
        this.player[index].src = videoUrl
        for (let i = 0; i < this.player.length; i++) {
          if (i === this.player.length - 1) {
            setTimeout(() => {
              this.loading = false
            }, 1000)
          }
        }

      },
    },
    mounted() {
      this.macList(this.region, 1)
    }
  }
</script>
<style lang="scss" scoped>
  .list ::v-deep button,
  ::v-deep .el-pager,
  ::v-deep .number {
    background: rgb(0, 59, 81);
    color: #fff;
  }

  ::v-deep .header-item,
  ::v-deep .ceil {
    font-size: 22px;
  }

  ::v-deep .el-pagination {
    position: absolute;
    right: 0;
    bottom: 20px;
  }

  ::v-deep .el-pagination__total {
    color: #fff;
    font-size: 25px;
  }

  .video-js {
    height: 220px;
    background: #000;
    width: 100%;
  }

  .opacity {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.8;
    z-index: 44443;
  }

  .close {
    position: fixed;
    top: 3%;
    right: 10%;
    width: 50px;
    height: auto;
    z-index: 557777666667;
    cursor: pointer;
  }

  .details {
    position: fixed;
    z-index: 557777666668;
    top: 0;
    left: 50%;
    cursor: pointer;
    background: url("../assets/bg1.png") no-repeat center;
    background-size: 100% 100%;
    width: 1300px;
    height: 95%;
    margin-left: -750px;

    .title {
      font-size: 45px;
      width: 100%;
      text-align: center;
      margin-top: 100px;
      font-weight: bold;
    }

    .items {
      display: flex;
      flex-wrap: wrap;
      margin-top: 70px;
      padding-left: 65px;

      .item {
        width: 580px;
        margin-bottom: 20px;
        margin-right: 20px;
        height: 350px;

        .name {
          font-size: 20px;
          color: #fff;
          width: 100%;
          text-align: center;
          font-weight: bold;
          margin-top: 20px;
        }
      }
    }
  }
</style>